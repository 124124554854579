/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Black.woff2') format('woff2'),
        url('Roboto-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Bold.woff2') format('woff2'),
        url('Roboto-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-BlackItalic.woff2') format('woff2'),
        url('Roboto-BlackItalic.woff') format('woff');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Italic.woff2') format('woff2'),
        url('Roboto-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-BoldItalic.woff2') format('woff2'),
        url('Roboto-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Light.woff2') format('woff2'),
        url('Roboto-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-LightItalic.woff2') format('woff2'),
        url('Roboto-LightItalic.woff') format('woff');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-MediumItalic.woff2') format('woff2'),
        url('Roboto-MediumItalic.woff') format('woff');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Medium.woff2') format('woff2'),
        url('Roboto-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Thin.woff2') format('woff2'),
        url('Roboto-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Regular.woff2') format('woff2'),
        url('Roboto-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Roboto';
    src: url('Roboto-ThinItalic.woff2') format('woff2'),
        url('Roboto-ThinItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}
body{
    font-family: 'Roboto' !important;
font-size: 16px;
}
/*=========================== Common style ===========================*/
a{
    text-decoration: none !important;
}
.mr-3{margin-right: 13px;}
.mr-2{margin-right: 10px;}
.mr-1{margin-right: 8px;}
/*----------------- COLOR -----------------*/
.bg_light{
    background-color: #fcf7f4;
}
.bg_lign_yellow{
    background-color: #FEF9EB;
}
.nav-btn-two:hover {
    background-color: #7C1F6A;
    color: #ffffff;
}
.nav-btn-two {
    padding: 10px 30px;
    background-color: #7C1F6A;
    color: #ffffff !important;
    border-radius: 50px;}
.inner-banner .inner-title ul li a{color:#fff;}
/*----------------- Padding -----------------*/
.header_padding{
    padding: 22px 0px
}
.section_padding{
    padding:90px 0px; 
}
/*----------------- common -----------------*/
.navbar li{
   padding-left: 15px;
   padding-right: 15px;
}
.navbar li a{
    color: #676767;
    font-weight: 500;
    font-size: 17px;
}
.navbar li a:hover{
   color: #7C1F6A;
}
.navbar-nav .nav-item .dropdown-menu {
    box-shadow: 0 0 30px 0 rgb(0 0 0 / 5%);
    background: #0d1028;
    position: absolute;
    top: 80px;
    left: -20px;
    width: 250px;
    z-index: 99;
    display: block;
    padding-top: 20px;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 20px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}
.navbar-nav .nav-item .dropdown-menu li{
    padding: 10px 5px;
}
.navbar-nav .nav-item .dropdown-menu.show{
    transform: inherit !important;
    opacity: 1 !important;
    visibility: inherit !important;
    top: 40px !important;
    
}
.navbar-nav .nav-item .dropdown-menu {
    z-index: 5;
    border: none;
    padding: 0;
    border-radius: 0;
    background-color: #ffffff !important;
    transform: scaleX(0);
}
.listing{
    padding:0px;
    margin: 0px;
}
.listing  li{
    list-style: none;
    padding: 8px;
}
.listing.hor li{
    display: inline-block;
}
.drop_listing {
    background-color: #ffffff;
    box-shadow: 0px 0px 29px 0px rgb(102 102 102 / 10%);
    border-radius: 0;
    margin-top: 20px;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
}
.drop_listing li {
    transition: .6s;
    color: #000000;
    font-weight: 400;
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 0;
    width: 180px;
    border-bottom: 1px solid #e1e1e1;
}
.big_img{
    position: relative;
    z-index: 1;
    margin-top: 60px;
    margin-bottom: 110px;
    margin-left: 40px;
    border-radius: 50px;
}
.big_imgf{
    position: relative;
    z-index: 1;
    margin-top: 1px;
    margin-bottom: 110px;
    margin-left: 40px;
    border-radius: 50px;
}
.big_imgf img{border-radius: 50px;}
.big_imgf::before{
    content: '';
    position: absolute;
    z-index: -1;
    bottom: -40px;
    left: -40px;
    background-color: #DBD2FF;
    width: 100%;
    height: 100%;
    border-radius: 50px;
}
.big_img img{border-radius: 50px;}
.big_img::before{
    content: '';
    position: absolute;
    z-index: -1;
    bottom: -40px;
    left: -40px;
    background-color: #DBD2FF;
    width: 100%;
    height: 100%;
    border-radius: 50px;
}
.banner_padding{
    padding: 0px 0px 50px 0px;
}
.banner_content h1{
    font-size: 73px;
    margin-bottom: 20px;
    font-weight: bold;
}
.banner_content p{
    font-size: 20px;
    margin-bottom: 30px;
    color: #000000;
    max-width: 540px;
}
.property-features-img {
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
}
.property-features-img::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: #DBD2FF;
    border-radius: 50px;
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
    clip-path: polygon(0 0, 100% 0, 100% 93%, 0% 100%);
}
.property-features-img .shape {
    position: absolute;
    z-index: -2;
    right: -40px;
    bottom: -20px;
}
.section_title h2{
    font-size: 35px;
    font-weight: 600;
    margin-top: 5px;
    line-height: 1.4;
    color: #000000;
}
.card{
    background-color: #ffffff;
    box-shadow: 0 0 15px rgb(0 0 0 / 5%);
    padding: 20px 20px 30px;
    margin-bottom: 30px;
    border:none !important;
}
.btn_outline{
    font-size: 14px;
    color: #676767;
    margin-bottom: 0;
    padding: 8px 20px;
    border: 1px solid #7C1F6A;
    border-radius: 50px;
}
.property_features .card-img-top{
    width: 45px;
}
.property_features .card h4{
    font-size: 20px;
    font-weight: bold;
}
.employee-img {
    position: relative;
    z-index: 1;
    margin-bottom: 70px;
    margin-right: 40px;
}
.employee-img::before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: -40px;
    right: -40px;
    width: 100%;
    height: 100%;
    border-radius: 50px;
    background-color: #DBD2FF;
}
.employee-img img {
    border-radius: 50px;
}
footer{
    padding: 40px 0px;
}
footer .card{
    background-color: transparent !important;
    border:none !important;
    box-shadow: none !important;
    margin-bottom: 0px !important;
}
.social_link li a{
    height: 42px;
    width: 42px;
    border-radius: 50px;
    padding: 0px 13px;
    font-size: 17px;
    display: block;
    color: #fff;
    transition: all 0.5s;
    line-height: 43px;

}
.social_link li a:hover {
    background-color: #000000 !important;
    animation: tada 1s linear;
}
.social_link li:first-child a{
    background-color: #3b5998;
}
.social_link li:nth-child(2) a{
    background-color: #55acee;
}
.social_link li:nth-child(3) a{
    background-color: #3f729b;
}
.social_link li:nth-child(4) a{
    background-color: #c80000;
}
.user_link li a,.address li a{
    padding-left: 24px;
    color: #676767;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    position: relative;

}
.user_link li a::before{
    content: '\F285';
    font-family: bootstrap-icons !important;
    font-size: 14px;
    position: absolute;
    font-weight: 700;
    left: -2px;
    top: 0px;
}
.user_link  li a:hover,.address li a:hover,.address li a:hover i {
color: #000000 !important;
    animation: tada 1s linear;
}
.user_link li a:hover::before{
    animation-duration: 10s;
    animation-name: animate__fadeOutRight;
    transition: all 0.2s;
    left: 5px;

}
.address li a{
    padding-left: 0px !important;
  
}
.address li a i{
    color: #FD8980;
    font-size: 18px;
}
.inner-bg1 {
    background-image: url('inner-banner1.jpg');
    position: relative;
}
.inner-banner::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    background-color: #000000;
    opacity: 0.5;
}
.inner-banner .inner-title {
    padding-top: 150px;
    padding-bottom: 150px;
}
.inner-banner .inner-title h3 {
    font-size: 35px;
    color: #ffffff;
    font-weight: 500;
    position: relative;
}
.inner-banner .inner-title ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
.inner-banner .inner-title ul li {
    font-size: 18px;
    color: #ffffff;
    display: inline-block;
    margin-right: 30px;
    position: relative;
}
.inner-banner .inner-title ul li::before {
    content: '';
    position: absolute;
    top: 14px;
    right: -20px;
    background-color: #ffffff;
    width: 7px;
    height: 7px;
}
.inner-banner .inner-title ul li:last-child::before{
    display: none
}
.detail-content h3{
    font-size: 28px;
    margin-bottom: 20px;
    font-weight: 600;
}
/*=========================== Media style ===========================*/
@media only screen and (min-width: 999px) {
    .dfe{
        display: flex;
        justify-content: flex-end;
    }
    .dfc{
        display: flex;
        justify-content: center;
    }
    
}
@media only screen and (min-width: 992px){
    .navbar-brand{display: none;}
}